import {
    H1_360_Bold,
    H1_768_Bold,
    H2_1366_Bold,
    H4_1366,
    H4_360,
    H4_768,
} from "src/templates/t27/constants/typography";

import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../../constants/mediaquerys";

const Default = css`
    padding-top: 16px;
    padding-bottom: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;

    .parent-link {
        h2 {
            cursor: pointer;
            transition: color 0.3s ease-in;
            ${H1_360_Bold}

            &:hover {
                /* color: var(--front-3); */
                opacity: 0.7;
            }
        }

        .sub-links {
            .sub-link {
                h4 {
                    cursor: pointer;
                    transition: color 0.3s ease-in;
                    ${H4_360}

                    &:hover {
                        /* color: var(--front-3); */
                        opacity: 0.7;
                    }
                }
            }
        }
    }
`;

const Silver = css`
    padding-top: 27px;
    padding-bottom: 27px;

    .parent-link {
        h2 {
            ${H1_768_Bold}

            .sub-links {
                .sub-link {
                    h4 {
                        ${H4_768}
                    }
                }
            }
        }
    }
`;

const Gold = css``;

const Platinum = css`
    padding-top: 31px;
    padding-bottom: 39px;
    gap: 16px;

    .parent-link {
        h2 {
            ${H2_1366_Bold}
        }

        .sub-links {
            .sub-link {
                h4 {
                    ${H4_1366}
                }
            }
        }
    }
`;

const Diamond = css``;

export const SModalLinks = styled.ul`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
