import styled, { FlattenInterpolation, ThemeProps, css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";
import { convertHex } from "src/common/utils/colorHelper";

interface IProps {
    isExpanded?: boolean;
    positionTimeoutDurationWithMs: number;
}

type IStyle = FlattenInterpolation<ThemeProps<IProps>>;

const Default = ({ positionTimeoutDurationWithMs, isExpanded }: IProps): IStyle => css`
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${convertHex("#797979", 0.75)};
    z-index: 101;
    transition: background-color ${positionTimeoutDurationWithMs}ms;

    ${!isExpanded &&
    css`
        background-color: ${convertHex("#797979", 0)};
    `}
    .frc-captcha {
        color: white;
        background-color: #008bc5;
    }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SPlanningDateModalOverlay = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
